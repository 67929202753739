import React, { PureComponent } from "react";
import ExpandedOfferBlock from "./ExpandedOfferBlock";
import CompareModalScreen from "./CompareModalScreen";
import {
  initApplicationResponseSelector,
  userInfoSelector,
  availableApplicationsSelector,
  notAvailableApplicationsSelector,
  trendingsVisitID,
  recommendationsSelector,
  subscribingDataSelector,
  discountDataSelector,
} from "../selectors";
import {editCart, providerFailed, selectOffer} from '../actions';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { BlueBtn } from "components/Controls";
import { AnalyticAPI } from "api/analytic";
import ModalSortBtns from "components/Controls/ModalSortBtns";
import CheckIconGreen from "assets/img/check-icon-green.png";
import { closeModal } from "../../MultistepModal/actions";

import "./styles.scss";
import { OFFER_ORDER } from "./constants";
import { UOWN_FIRST_OFFER_ORDER } from "./constants";
import { CustomTooltip } from "../../../components/DataDisplay";
import Recommendations from "components/DataDisplay/Recommendations";
import CartInfo from "components/DataDisplay/CartInfo";
import {logEvent} from '../../EmbeddableWidget/actions';

class Step3 extends PureComponent {
  state = {
    compareOffers: [],
    compareModal: false,
    orderedOffers: [],
    offersWithOptions: [],
    isCompareMode: false,
    maxApproval: 0,
  };

  constructor(props) {
    super(props);
    this.state.orderedOffers = this.props.availableApplications;
  }

  componentDidMount() {
    const {
      availableApplications,
      notAvailableApplications,
      token,
      visitID,
      logEvent
    } = this.props;

    AnalyticAPI.send_analytic_event(token, "approvals", visitID);

    if (
      availableApplications.length === 0 &&
      notAvailableApplications.length > 0
    ) {
      const maxApproval = this.maxApprovalAmount();
      this.setState({ maxApproval });
    }

    const uownFirst = availableApplications
      ?.find((app) => app.provider === "uown")
      ?.options?.some((option) => option.initial_payment === 0);
    let sortedOffers;

    if (uownFirst) {
      sortedOffers = [...availableApplications].sort((a, b) => {
        const indexA = UOWN_FIRST_OFFER_ORDER.indexOf(a.provider);
        const indexB = UOWN_FIRST_OFFER_ORDER.indexOf(b.provider);
        return (indexA === -1) - (indexB === -1) || indexA - indexB;
      });
    } else {
      sortedOffers = [...availableApplications].sort((a, b) => {
        const indexA = OFFER_ORDER.indexOf(a.provider);
        const indexB = OFFER_ORDER.indexOf(b.provider);
        return (indexA === -1) - (indexB === -1) || indexA - indexB;
      });
    }

    this.setState({ orderedOffers: sortedOffers });

    if (availableApplications.length === 0 && notAvailableApplications.length > 0) {
      logEvent({name: 'approved partial value'})
    } else if (availableApplications.length > 0) {
      logEvent({name: 'approved full cart'})
    }
  }

  maxApprovalAmount = () => {
    const { notAvailableApplications } = this.props;
    return Math.max(...notAvailableApplications.map((o) => o.approved_amount));
  };

  callbackOrdersOffersData = (offers) => {
    this.setState({ orderedOffers: [...offers] });
  };

  callbackCompareToggle = () => {
    this.setState({ isCompareMode: !this.state.isCompareMode });
  };

  addToCompare = (offers) => {
    this.setState({ compareOffers: [...offers] });
  };

  renderCompareBtn = () => {
    const { isCompareMode, compareOffers } = this.state;

    if (isCompareMode && compareOffers.length > 1) {
      return (
        <div className="compare-total">
          <BlueBtn onClick={() => this.toggleCompareModal()}>
            COMPARE SELECTED PLANS ({compareOffers.length})
          </BlueBtn>
        </div>
      );
    }
  };

  toggleCompareModal = () => {
    this.setState({ compareModal: !this.state.compareModal });
  };

  selectOffer = (providerName) => {
    const { compareOffers } = this.state;
    const offer = compareOffers.find(
      (elem) => elem.specificProviderName == providerName
    );
    if (!offer) {
      return;
    }
    this.props.selectOffer({ selectedOffer: offer.dataForApprouve });
  };

  addOffersForSort = (offer) => {
    this.setState(({ offersWithOptions }) => {
      const newSortedOffers = offersWithOptions.filter(
        (elem) => elem.specific_provider_name !== offer.specific_provider_name
      );
      return { offersWithOptions: [...newSortedOffers, offer] };
    });
  };

  render() {
    const {
      initAppResponse,
      availableApplications,
      notAvailableApplications,
      recommendations,
      items,
      discount,
      editCart,
      logEvent,
    } = this.props;

    const {
      isCompareMode,
      compareOffers,
      compareModal,
      orderedOffers,
      offersWithOptions,
    } = this.state;

    const isFlexshopperOnly =
      orderedOffers.length === 1 &&
      orderedOffers[0].provider === "flexshopper" &&
      orderedOffers[0].authentication_is_required;

    return (
      <div>
        <div className="header-text-block step3-header-block">
          <div className="header-text-block__icon">
            <img src={CheckIconGreen} />
          </div>
          <div className="congrats-text">Congrats, You’re Prequalified!</div>
          <span>
            <CustomTooltip
              text={
                "A prequalification is not a guarantee of final approval. Final approval amount may vary. In some instances, depending on the information in the application, applicants may be approved for an amount greater than prequalification amount."
              }
            />
          </span>
        </div>
        <div className="header-text-block pb">
          <div className="info-text">
            Please review and select a payment plan option below to complete
            your order.
          </div>
        </div>

        <div className="step3 paypair-step-content">
          <CompareModalScreen
            closeModal={this.toggleCompareModal}
            isOpen={compareModal}
            offers={compareOffers}
            selectOffer={this.selectOffer}
          />
          {isFlexshopperOnly && (
            <div>
              <h2 className="subtitle font-center">
                We've found your Flexshopper account!
              </h2>
              <h2 className="title2 font-center "></h2>
            </div>
          )}

          {orderedOffers.length > 1 && (
            <ModalSortBtns
              callbackOrderedOffers={this.callbackOrdersOffersData}
              callbackCompareToggle={this.callbackCompareToggle}
              offers={orderedOffers}
              offersWithOptions={offersWithOptions}
              isCompareMode={isCompareMode}
              logEvent={logEvent}
            />
          )}

          <div className="line"></div>

          <div className="step3__content flex flex--column">
            {availableApplications.length === 0 &&
              notAvailableApplications.length > 0 &&
              recommendations &&
              Object.keys(recommendations).length > 0 &&
              recommendations.recommendations && (
                <>
                  {/*<CartInfo*/}
                  {/*  lineItems={items.line_items}*/}
                  {/*  totalPrice={items.total_price}*/}
                  {/*  shipping={items.shipping}*/}
                  {/*  discount={discount}*/}
                  {/*/>*/}
                  <Recommendations
                    lineItems={items.line_items}
                    totalPrice={items.total_price}
                    recommendations={recommendations.recommendations}
                    itemId={recommendations.item_id}
                    quantity={recommendations.quantity}
                    discountRequired={!!recommendations.discount_required}
                    maxApprovalAmount={this.state.maxApproval}
                    editCart={editCart}
                    logEvent={logEvent}
                  />
                </>
              )}
            {orderedOffers.map((offer, index) => {
              return (
                <ExpandedOfferBlock
                  offer={offer}
                  key={offer.specific_provider_name}
                  isCompareMode={isCompareMode}
                  addToCompare={this.addToCompare}
                  compareOffers={compareOffers}
                  addOffersForSort={this.addOffersForSort}
                />
              );
            })}
            {this.renderCompareBtn()}
            {notAvailableApplications.length > 0 && (
              <div>
                <p className="section_title">
                  The plans below are approvals but do not cover your cart
                  total.
                </p>
                <div className="section_header_message">
                  In order to complete your purchase with these plans, please
                  edit your cart so your total is equal to or less than the
                  approval amount.
                </div>
                <div className="gray-section">
                  <div className="not_enought_amount-block">
                    {notAvailableApplications.map((offer, index) => {
                      return (
                        <ExpandedOfferBlock
                          key={`${index}-${offer.provider}`}
                          offer={offer}
                          available={false}
                          addToCompare={this.addToCompare}
                          compareOffers={compareOffers}
                          addOffersForSort={this.addOffersForSort}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  initAppResponse: initApplicationResponseSelector(),
  availableApplications: availableApplicationsSelector(),
  notAvailableApplications: notAvailableApplicationsSelector(),
  userInfo: userInfoSelector(),
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  recommendations: recommendationsSelector(),
  items: subscribingDataSelector(),
  discount: discountDataSelector(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    closeModal: () => dispatch(closeModal()),
    editCart: (values) => dispatch(editCart(values)),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

Step3 = connect(mapStateToProps, mapDispatchToProps)(Step3);

export default Step3;
