import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { selectedOfferSelector, trendingsVisitID } from "../selectors";
import { connect } from "react-redux";
import PaytomorrowContract from "./PaytomorrowContract";
import FlexshopperContract from "./FlexshopperContract";
import OkinusContract from "./OkinusContract";
import UownContract from "./UownContract";
import AffContract from "./AffContract";
import AcimaContract from "./AcimaContract";
import SnapContract from "./SnapContract";
import KoalafiContract from "./KoalafiContract";
import OwnleaseContract from "./OwnleaseContract";
import KornerstoneContract from "./KornerstoneContract";
import ProgressiveContract from "./ProgressiveContract";

import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

class ContractStep extends Component {
  componentDidMount() {
    const { token, visitID } = this.props;

    AnalyticAPI.send_analytic_event(token, "congrats_screen_viewed", visitID);
  }

  renderPaytomorrowContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <PaytomorrowContract
        contractUrl={offer?.contractUrl || selectedOffer.contractUrl}
        hidden={hidden}
      />
    );
  };

  renderFlexshopperContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <FlexshopperContract
        contract={offer?.contract || selectedOffer.contract}
        hidden={hidden}
      />
    );
  };

  renderOkinusContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <OkinusContract selectedOffer={offer || selectedOffer} hidden={hidden} />
    );
  };

  renderUownContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <UownContract
        contractUrl={offer?.contractUrl || selectedOffer.contractUrl}
        hidden={hidden}
      />
    );
  };

  renderAffContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <AffContract
        contractUrl={offer?.contractUrl || selectedOffer.contractUrl}
        hidden={hidden}
      />
    );
  };

  renderAcimaContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <AcimaContract selectedOffer={offer || selectedOffer} hidden={hidden} />
    );
  };

  renderSnapContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <SnapContract selectedOffer={offer || selectedOffer} hidden={hidden} />
    );
  };

  renderKoalafiContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <KoalafiContract selectedOffer={offer || selectedOffer} hidden={hidden} />
    );
  };

  renderOwnleaseContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <OwnleaseContract
        contractUrl={offer?.contractUrl || selectedOffer.contractUrl}
        hidden={hidden}
      />
    );
  };

  renderKornerstoneContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <KornerstoneContract
        contractUrl={offer?.contractUrl || selectedOffer.contractUrl}
        hidden={hidden}
      />
    );
  };

  renderProgressiveContract = () => {
    const { selectedOffer, offer, hidden } = this.props;
    return (
      <ProgressiveContract
        contractUrl={offer?.contractUrl || selectedOffer.contractUrl}
        hidden={hidden}
      />
    );
  };

  renderSwitch = (provider) => {
    switch (provider) {
      case "paytomorrow":
        return this.renderPaytomorrowContract();
      case "flexshopper":
        return this.renderFlexshopperContract();
      case "okinus":
        return this.renderOkinusContract();
      case "uown":
        return this.renderUownContract();
      case "aff":
        return this.renderAffContract();
      case "acima":
        return this.renderAcimaContract();
      case "snap":
        return this.renderSnapContract();
      case "koalafi":
        return this.renderKoalafiContract();
      case "ownlease":
        return this.renderOwnleaseContract();
      case "kornerstone":
        return this.renderKornerstoneContract();
      case "progressive":
        return this.renderProgressiveContract();
      default:
        return null;
    }
  };

  render() {
    const { selectedOffer, offer } = this.props;
    return this.renderSwitch(offer ? offer.provider : selectedOffer.provider);
  }
}

const mapStateToProps = createStructuredSelector({
  selectedOffer: selectedOfferSelector(),
  token: tokenSelector(),
  visitID: trendingsVisitID(),
});

ContractStep = connect(mapStateToProps, null)(ContractStep);

export default ContractStep;
