import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { BlueBtn } from "components/Controls";
import Compare from "./copmare";
import PaytomorrowOffer from "../PaytomorrowOffer";
import FlexshopperOffer from "../FlexshopperOffer";
import GenesisOffer from "../GenesisOffer";
import OkinusOffer from "../OkinusOffer";
import UownOffer from "../UownOffer";
import AffOffer from "../AffOffer";
import AcimaOffer from "../AcimaOffer";
import SnapOffer from "../SnapOffer";
import KoalafiOffer from "../KoalafiOffer";
import OwnleaseOffer from "../OwnleaseOffer";
import KornerstoneOffer from "../KornerstoneOffer";
import ProgressiveOffer from "../ProgressiveOffer";
import { providerFailed } from "../../actions";
import { selectCompareData } from "./select_compare_data";
import { createStructuredSelector } from "reselect";
import {
  appRequestSelector,
  cartAmountSelector,
  prequalificationSelector,
  selectedOfferSelector,
} from 'containers/MultistepForm/selectors.js';
import RightArrow from "assets/img/icon-arrow-right.svg";
import "./styles.scss";
import {logEvent} from '../../../EmbeddableWidget/actions';

class ExpandedOfferBlock extends Component {
  state = {
    isExpanded: false,
    selectedForCompare: false,
    selectedMultiOffer: {
      initial_payment: null,
    },
    hasError: false,
    isDisabled: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { available, selectedOffer } = this.props;
    const isDisabled =
      selectedOffer || Object.keys(selectedOffer || {}).length
        ? true
        : !available;

    if (prevState.isDisabled !== isDisabled) {
      this.setState({ ...prevState, isDisabled: isDisabled });
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.props.providerFailed(this.props.offer);
  }

  toggleExpandView = () => {
    const { offer } = this.props;
    const { isExpanded } = this.state;
    const { logEvent } = this.props;
    !isExpanded && logEvent({name: 'view plan details', payload: {lender: offer.provider}})

    this.setState(() => ({
      isExpanded: !isExpanded,
    }));
  };

  compareMethod = () => {
    const { isCompareMode } = this.props;
    const { isExpanded } = this.state;
    if (isCompareMode) {
      return false;
    }
    return isExpanded;
  };

  renderOffer = () => {
    const { offer } = this.props;
    const { isDisabled } = this.state;

    const extendedOffer = {
      ...offer,
      cart_total: parseFloat(this.props.cartAmountSelector) - parseFloat(offer.full_taxes) || this.props.cartAmountSelector,
    };

    const logContinueEvent = () => {
      const { logEvent } = this.props;
      logEvent({name: 'continue with', payload: {lender: offer.provider}})
    }

    switch (offer.provider) {
      case "paytomorrow":
        return (
          <PaytomorrowOffer
            offer={extendedOffer}
            available={!isDisabled}
            getSelectedOffer={this.getSelectedOffer}
            isExpanded={this.compareMethod()}
            logContinueEvent={logContinueEvent}
            // selectOption={this.selectOption}
          />
        );
      case "flexshopper":
        return (
          <FlexshopperOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      case "genesis":
        return (
          <GenesisOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      case "okinus":
        return (
          <OkinusOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      case "uown":
        return (
          <UownOffer
            offer={extendedOffer}
            getSelectedOffer={this.getSelectedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            logContinueEvent={logContinueEvent}
          />
        );
      case "aff":
        return (
          <AffOffer
            offer={extendedOffer}
            getSelectedOffer={this.getSelectedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            logContinueEvent={logContinueEvent}
          />
        );
      case "acima":
        return (
          <AcimaOffer
            offer={extendedOffer}
            getSelectedOffer={this.getSelectedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            logContinueEvent={logContinueEvent}
          />
        );
      case "snap":
        return (
          <SnapOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      case "koalafi":
        return (
          <KoalafiOffer
            offer={extendedOffer}
            getSelectedOffer={this.getSelectedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            logContinueEvent={logContinueEvent}
          />
        );
      case "ownlease":
        return (
          <OwnleaseOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      case "kornerstone":
        return (
          <KornerstoneOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      case "progressive":
        return (
          <ProgressiveOffer
            offer={extendedOffer}
            available={!isDisabled}
            isExpanded={this.compareMethod()}
            getSelectedOffer={this.getSelectedOffer}
            logContinueEvent={logContinueEvent}
          />
        );
      default:
        return "";
    }
  };

  getSelectedOffer = (data) => {
    this.props.addOffersForSort(data);
    this.setState({ selectedMultiOffer: data });
  };

  setAsCompare = () => {
    const { selectedForCompare, selectedMultiOffer } = this.state;
    const { offer, compareOffers, isCompareMode, appRequest } = this.props;
    let newCompareOffers = [];
    if (!isCompareMode) {
      return;
    }
    if (selectedForCompare) {
      newCompareOffers = compareOffers.filter(
        (elem) => elem.specificProviderName !== offer.specific_provider_name
      );
      this.setState({ selectedForCompare: false });
      this.props.addToCompare(newCompareOffers);
    }
    if (!selectedForCompare && compareOffers.length < 3) {
      const dataForCompare = selectCompareData(selectedMultiOffer, appRequest.residenceInfo.state);
      newCompareOffers = [...compareOffers, dataForCompare];
      this.setState({ selectedForCompare: true });
      this.props.addToCompare(newCompareOffers);
    }
  };

  modeRender = () => {
    const { isExpanded, selectedForCompare } = this.state;
    const { isCompareMode, prequalification, offer, available } = this.props;
    if (prequalification) {
      return;
    }
    if (isCompareMode) {
      return (
        <div className="text-right">
          <Compare selectedForCompare={selectedForCompare} />
        </div>
      );
    }
    if (offer.provider == "snap" && !available) {
      return null;
    }

    return (
      <div className="expanded-block-component__expand-btn">
        <BlueBtn
          customClass={isExpanded ? "is-active" : ""}
          onClick={this.toggleExpandView}
          isExpanded={isExpanded}
        >
          {isExpanded ? (
            "Hide Plan Details"
          ) : (
            <span>
              View Plan Details
              <img
                src={RightArrow}
                className="icon-arrow-down"
                alt="down-arrow"
              />
            </span>
          )}
        </BlueBtn>
      </div>
    );
  };

  render() {
    const { offer, isCompareMode } = this.props;
    const { hasError, selectedForCompare } = this.state;

    if (!offer) {
      return "";
    }

    if (hasError) {
      return "";
    }

    return (
      <div
        onClick={this.setAsCompare}
        className={classNames(
          "expanded-block-component",
          `${offer.specific_provider_name}`,
          {
            expanded: true,
            compare: isCompareMode,
            selectedForCompare: isCompareMode && selectedForCompare,
          }
        )}
      >
        {this.renderOffer()}
        {this.modeRender()}
      </div>
    );
  }
}

ExpandedOfferBlock.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};
ExpandedOfferBlock.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  prequalification: prequalificationSelector(),
  appRequest: appRequestSelector(),
  selectedOffer: selectedOfferSelector(),
  cartAmountSelector: cartAmountSelector(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    providerFailed: (data) => dispatch(providerFailed(data)),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

ExpandedOfferBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpandedOfferBlock);

export default ExpandedOfferBlock;
