import React, { Component, useState } from "react";
import {connect, Provider} from 'react-redux';
import { reduxForm, Field, change } from "redux-form";
import { BlueBtn } from "components/Controls";
import { breadcrumbMap, getForms } from "../constants";
import Breadcrumb from "components/Breadcrumb";
import { onlyNumbers } from "utils/normalize";
import { required, isNumEqual9, validate } from "utils/validations";
import { normalizeSNN } from "utils/normalize";
import { initApplication, continueInitApplication } from "../actions";
import {
  appRequestSelector,
  loadingSelector,
  formValuesSelector,
  affiliatedProviderSelector,
  trendingsVisitID, activeProviders,
  affAgreements
} from '../selectors';
import AdditionalInfoBlock from "components/DataDisplay/AdditionalInfoBlock";
import { formValueSelector } from "redux-form";
import { Submitted } from "components/FlexshopperMetrics";
import { AnalyticAPI } from "api/analytic";
import { ApplicationRequestAPI } from "api/application_request";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import Secure from "assets/img/lock_secure.png";
// TODO: move to common dir
import { renderField } from "./input_helpers";
import { renderPrivacyPolicy } from "../privacy_policy";
import { renderHeader } from "../header";
import "./styles.scss";
import FinancialInfoForm from "components/FinancialInfoForm";
import DateOfBirth from "components/Fields/DateOfBirth";
import {ProvidersApi} from '../../../api/providers';
import {logEvent} from '../../EmbeddableWidget/actions';

const selector = formValueSelector("form");

class Step2 extends Component {
  state = {
    additionalData: {},
    additionalDataValid: false,
    showPayoutDayOption: false,
    payoutDay: "first",
    payFrequency: "",
    clearLastPaydate: false,
    nextPaycheckDate: null,
  };

  componentDidMount() {
    const { token, visitID, values } = this.props;
    this.setState({ phone: values.phone });
    AnalyticAPI.send_analytic_event(token, "page2", visitID);
    const preInitData = this.buildPreInitData();
    ApplicationRequestAPI.koalafiCreate(token, preInitData).then((data) => {
      const koalafiId = data.koalafi.id;
      const script = document.createElement("script");
      script.setAttribute(
        "src",
        `https://content.koalafi.com/fp/tags.js?org_id=${process.env.KOALAFI_ORG_ID}&session_id=${koalafiId}`
      );
      document.head.appendChild(script);
    });
  }

  submitForm = (values) => {
    const {logEvent, initApplication} = this.props;

    initApplication({ values: this.buildBodyToInitApplication() });
    logEvent({name: 'see payment plans'})
  };

  buildPreInitData = () => {
    const { appRequest, values } = this.props;

    return {
      application: {
        personalInfo: {
          phone: onlyNumbers(values.phone),
          firstName: appRequest.personalInfo.firstName,
          lastName: appRequest.personalInfo.lastName,
          email: appRequest.personalInfo.email,
        },
        shipping: appRequest.shipping,
        items: appRequest.items,
        orderId: appRequest.orderId,
      },
    };
  };
  buildBodyToInitApplication = () => {
    const { appRequest, values } = this.props;
    const { additionalData, nextPaycheckDate, phone } = this.state;
    const applicationRequest = {
      personalInfo: {
        phone: phone && onlyNumbers(phone),
        dob: `${values.year}-${values.month}-${values.day}`,
        ssn: values.ssn && onlyNumbers(values.ssn),
      },
      consents: {
        agreeTcpa: values.agreeTcpa || false,
      },
      employmentInformation: {
        payFrequency: values.payFrequency,
        monthlyIncome: values.income && parseFloat(onlyNumbers(values.income)),
        last_payday_on: values.lastPaycheck,
        next_payday_on: nextPaycheckDate,
        direct_deposit: "true",
      },
    };

    if (appRequest.provider_name === "flexshopper") {
      applicationRequest.has_additional_info = true;
      applicationRequest.additional_info_data = {};
      if (additionalData.routingNum && additionalData.accountNum) {
        applicationRequest.additional_info_data.bankAccount = {
          routing: additionalData.routingNum,
          account: additionalData.accountNum,
          type: "checking",
        };
      }
    }
    return applicationRequest;
  };

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      additionalDataValid: data.valid,
    });
  };

  renderForm = () => {
    const { month, day, year } = this.props;
    return (
      <div>
        <div className="step2__form paypair-form step2-border_box">
          <div className="requeired-field_text">* Required field</div>
          <div className="form-item-group">
            <div className="form-item date-of-birthday">
              <DateOfBirth
                month={month}
                day={day}
                year={year}
                resetDay={() => this.props.change("day", "")}
              />
            </div>
            <div className="form-item form-item--ssn">
              <Field
                name="ssn"
                validate={[required, isNumEqual9]}
                component={renderField}
                normalize={normalizeSNN}
              />
            </div>
          </div>
          <FinancialInfoForm
            setValue={this.setState.bind(this)}
            payoutDay={this.state.payoutDay}
            values={this.props}
            clearLastPaydate={this.state.clearLastPaydate}
            showPayoutDayOption={this.state.showPayoutDayOption}
            payoutFrequency={this.state.payoutFrequency}
          />
        </div>
        <div className="div-lock-green">
          <div>
            <img src={Secure} className="lock-img" />
          </div>
          <span className="lock-green">
            Your information is secure and only stored temporarily while we
            process your application.
          </span>
        </div>
      </div>
    );
  };

  render() {
    const {
      valid,
      loading,
      handleSubmit,
      hasAdditionalInfo,
      token,
      appRequest,
      providerName,
      activeProviders,
      affAgreements
    } = this.props;
    const { additionalDataValid, nextPaycheckDate } = this.state;

    const fullFormValid =
      appRequest.provider_name === "flexshopper"
        ? valid && additionalDataValid
        : valid;

    const FORMS = getForms();

    const activeBreadcrumb = FORMS.form2.breadcrumb;

    return (
      <form onSubmit={handleSubmit(this.submitForm)} className="step2">
        {renderHeader()}
        <Breadcrumb
          data={breadcrumbMap}
          activeBreadcrumb={"Application Details"}
        />
        <div className="step2__content flex flex--column flex__align--center">
          {this.renderForm()}
          <div className="Line-73"></div>
          {renderPrivacyPolicy(token, activeProviders, affAgreements)}

          {appRequest.provider_name === "flexshopper" && (
            <AdditionalInfoBlock onDataChange={this.setAdditionalData} />
          )}
          <div className="paypair-form paypair-form-padding">
            <div className="form-item">
              {loading && providerName == "flexshopper" && <Submitted />}
            </div>
          </div>
          <div className=" paypair-form-padding">
            <BlueBtn
              customClass="step2__submit-btn payment-plan_button"
              disabled={!fullFormValid || !nextPaycheckDate}
              loading={loading}
              type="submit"
            >
              See My Payment Plan Options
            </BlueBtn>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  token: tokenSelector()(state),
  values: formValuesSelector()(state),
  loading: loadingSelector()(state),
  hasAdditionalInfo: selector(state, "hasAdditionalInfo"),
  appRequest: appRequestSelector()(state),
  activeProviders: activeProviders()(state),
  providerName: affiliatedProviderSelector()(state),
  month: selector(state, "month"),
  day: selector(state, "day"),
  year: selector(state, "year"),
  visitID: trendingsVisitID()(state),
  affAgreements: affAgreements()(state),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    initApplication: (values) => dispatch(initApplication(values)),
    continueInitApplication: (values) =>
      dispatch(continueInitApplication(values)),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

Step2 = connect(mapStateToProps, mapDistpatchToProps)(Step2);

Step2 = reduxForm({
  validate,
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Step2);

export default Step2;
