import { prequalificationSelector } from "containers/MultistepForm/selectors";

export const appRequest = {
  metrixSessionId: "340e8635-77dc-4deb-bb73-389d45e24532+102959",
  personalInfo: {
    email: "valusha1591130188348@flexshopper.com",
    firstName: "Valbpuimib",
    lastName: "Grinsuftbq",
    phone: "2223444334",
    dob: "2002-06-02",
    ssn: "323333332",
  },
  residenceInfo: {
    street: "1224 Lala Lane",
    country: "US",
    city: "Cincinnati",
    state: "OH",
    zip: "45239",
  },
  visitId: "5643",
  orderId: "123",
  shipping: 0,
  items: [
    {
      description: "Rose Gold iPhone Xs 256GB",
      quantity: 1,
      price: 1000,
      category: "123",
      brand: "Apple",
      model: "iPhone Xs Rose Gold",
      sku: "1111",
      taxClass: "123",
      taxAmount: 10,
    },
  ],
  has_additional_info: true,
  additional_info_data: {
    bankAccount: { routing: "123123123", account: "123123", type: "checking" },
  },
  employmentInformation: {
    payFrequency: "weekly",
    monthlyIncome: 5000,
    last_payday_on: "2020-06-01",
    next_payday_on: "2020-06-08",
    direct_deposit: false,
  },
};

export const prequalificationCart = [
  {
    description: "Perualification Item",
    quantity: 1,
    price: 2500,
    category: "123",
    brand: "Perualification",
    model: "Perualification Item",
    sku: "perualification-item-1",
    taxAmount: 0,
  },
];

export const paytomorrowOffer = {
  provider: "paytomorrow",
  specific_provider_name: "paytomorrow",
  app_id: "123",
  lease_app_id: "123",
  approved_amount: 1050,
  payment_schedule: "monthly",
  approve_status: "approved",
  available: true,
  options: [
    {
      type: "Installment Loan",
      offer_id: 21,
      payment_period: 12,
      payment_period_rate: 103,
      buyout: true,
      buyout_rate: 23,
      initial_payment: 78.4,
      apr: 0,
      maxLoan: 5000,
    },
    {
      type: "Installment Loan",
      offer_id: 21,
      payment_period: 24,
      payment_period_rate: 105,
      first_period: 3,
      first_period_rate: 98,
      last_period: 9,
      last_period_rate: 100,
      buyout: true,
      buyout_rate: 11,
      initial_payment: 34,
      maxLoan: 5000,
    },
    {
      type: "Lease to Loan",
      offer_id: 21,
      payment_period: 28,
      payment_period_rate: 105,
      first_period: 3,
      first_period_rate: 98,
      last_period: 9,
      last_period_rate: 100,
      buyout: true,
      buyout_rate: 11,
      initial_payment: 34,
      maxLoan: 5000,
    },
  ],
};
export const greenwaveOffer = {
  provider: "paytomorrow",
  specific_provider_name: "greenwave",
  app_id: "123",
  lease_app_id: "123",
  approved_amount: 1050,
  payment_schedule: "monthly",
  approve_status: "approved",
  available: true,
  options: [
    {
      type: "NCN",
      offer_id: 21,
      payment_period: 12,
      payment_period_rate: 103,
      buyout: true,
      buyout_rate: 23,
      initial_payment: 78.4,
      apr: 0,
      maxLoan: 5000,
    },
  ],
};

export const katapultOffer = {
  provider: "paytomorrow",
  specific_provider_name: "katapult",
  app_id: "123",
  lease_app_id: "123",
  approved_amount: 1050,
  payment_schedule: "monthly",
  approve_status: "approved",
  available: true,
  options: [
    {
      type: "Lease to own",
      offer_id: 21,
      payment_period: 12,
      payment_period_rate: 103,
      buyout: true,
      buyout_rate: 23,
      initial_payment: 78.4,
      apr: 0,
      maxLoan: 5000,
    },
  ],
};

export const flexshopperOffer = {
  provider: "flexshopper",
  specific_provider_name: "flexshopper",
  app_id: "10feefe7-d5b3-40d2-9c18-5c1f5f724e34",
  lease_app_id: "123",
  approved_amount: 1200,
  approve_status: "approved",
  payment_schedule: "weekly",
  available: true,
  // approve_status: 'need_additionals',
  // authentication_is_required: false,
  verification_is_required: true,
  options: [
    {
      // type: "Lease to Own",
      // offer_id: null,
      // payment_period: 12,
      // payment_period_rate: 103,
      // buyout: false,
      // initial_payment: 78
      applicationId: "33feea5c-98dd-11ea-86d4-b2ba3d7c7627",
      approved: true,
      availableBalance: 1432,
      availableWeeklySpending: 58.66,
      buyout: false,
      buyout_rate: null,
      createdAt: "2020-05-18T07:57:21.000Z",
      initial_payment: 0,
      offer_id: null,
      payment_period: 12,
      payment_period_rate: "46.15",
      spendingLimit: 1432,
      status: "approved",
      type: "Lease to Own",
      cash_price: 1050,
      cost_of_rental: 950,
      total_payments_to_own: 2000,
    },
  ],
};

export const snapOffer = {
  provider: "snap",
  specific_provider_name: "snap",
  app_id: "10feefe7-d5b3-40d2-9c18-5c1f5f724e34",
  approve_status: "approved",
  approved_amount: 1200,
  payment_schedule: "weekly",
  available: true,
  is_finished: false,
  lease_app_id: "264188",
  options: [
    {
      due_today_text: 1.08,
      initial_payment: 1.08,
      maxLoan: 5000,
      offer_id: null,
      optionName: "B",
      payment_period: 10,
      payment_period_rate: 118.36,
      applicationId: "33feea5c-98dd-11ea-86d4-b2ba3d7c7627",
      approved: true,
      availableBalance: 1432,
      availableWeeklySpending: 58.66,
      buyout: false,
      buyout_rate: null,
      createdAt: "2020-05-18T07:57:21.000Z",
      spendingLimit: 1432,
      status: "approved",
      type: "Lease to Own",
      cash_price: 1050,
      cost_of_rental: 950,
      total_payments_to_own: 2000,
    },
  ],
};
export const snapOfferUnavailable = {
  provider: "snap",
  specific_provider_name: "snap",
  app_id: "0258f33a-9630-4325-9cce-08608bedff01",
  approve_status: "approved",
  approved_amount: 300.0,
  lease_app_id: "2304120010OH",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: null,
      maxLoan: "300.00",
    },
  ],
  available: false,
  payment_schedule: null,
  taxes: 55.0,
  full_taxes: 55.0,
  bank_data_is_required: true,
};

export const okinusOffer = {
  provider: "okinus",
  specific_provider_name: "okinus",
  app_id: "123",
  available: true,
  lease_app_id: "123",
  approved_amount: 2000,
  payment_schedule: "monthly",
  approve_status: "approved",
  options: [
    {
      type: "Lease to Own",
      offer_id: null,
      payment_period: 20,
      payment_period_rate: 103,
      buyout: false,
      initial_payment: 103,
      maxLoan: 2000,
    },
  ],
};

export const affOffer = {
  provider: "aff",
  specific_provider_name: "aff",
  app_id: "12",
  approve_status: "approved",
  approved_amount: 2425,
  lease_app_id: "123",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: "21",
      payment_period: 12,
      payment_period_rate: 90.45,
      buyout: false,
      buyout_rate: null,
      initial_payment: 70,
      first_payment: 70,
      payment_schedule: "semimonthly",
    },
  ],
  available: true,
  payment_schedule: "semimonthly",
};

export const acimaOffer = {
  provider: "acima",
  specific_provider_name: "acima",
  app_id: "12",
  approve_status: "approved",
  approved_amount: 2425,
  lease_app_id: "123",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: "21",
      payment_period: 12,
      payment_period_rate: 90.45,
      buyout: false,
      buyout_rate: null,
      initial_payment: 70,
      first_payment: 70,
      payment_schedule: "semimonthly",
    },
  ],
  available: true,
  payment_schedule: "semimonthly",
};

export const uownOffer = {
  provider: "uown",
  specific_provider_name: "uown",
  app_id: "12",
  approve_status: "approved",
  approved_amount: 1500,
  lease_app_id: "123",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: null,
      payment_period: 13,
      payment_period_rate: 35.52,
      buyout: false,
      buyout_rate: null,
      initial_payment: 0,
      first_payment: 75.52,
      payment_schedule: "weekly",
      contractUrl:
        "https://www.uownonline.com:443/tire00card?t=w&ata=d7e154cc-8446-4977-9a4c-d063c73d4516",
    },
    {
      type: "Lease to Own",
      offer_id: null,
      payment_period: 13,
      payment_period_rate: 71.04,
      buyout: false,
      buyout_rate: null,
      initial_payment: 0,
      first_payment: 111.04,
      payment_schedule: "biWeekly",
      contractUrl:
        "https://www.uownonline.com:443/tire00card?t=b&ata=d7e154cc-8446-4977-9a4c-d063c73d4516",
    },
  ],
  available: true,
  payment_schedule: "monthly",
};

export const genesisOffer = {
  provider: "genesis",
  specific_provider_name: "genesis",
  app_id: "123",
  available: true,
  lease_app_id: "123",
  approved_amount: 2000,
  payment_schedule: "monthly",
  approve_status: "approved",
  options: [
    {
      type: "Credit Line",
      offer_id: null,
      payment_period: 20,
      payment_period_rate: 103,
      buyout: false,
      initial_payment: 0,
    },
  ],
};

export const koalafiLoOffer = {
  provider: "koalafi",
  specific_provider_name: "koalafi",
  app_id: "af9ff294-6518-44d3-adca-826d0af706b9",
  approve_status: "approved",
  approved_amount: 10000.0,
  lease_app_id: "2021670",
  is_finished: false,
  options: [
    {
      type: "Koalafi Loan",
      offer_id: 7,
      payment_period: 36,
      payment_period_rate: "42.12",
      buyout: false,
      buyout_rate: null,
      initial_payment: 300.0,
      first_payment: 300.0,
      approved_amount: 10000,
    },
  ],
  available: true,
  payment_schedule: "weekly",
  taxes: 0.55e2,
  full_taxes: 0.55e2,
  order_id: "4e0c5c61-b686-4db0-b12e-e384d751b6e5",
};

export const koalafiLeOffer = {
  provider: "koalafi",
  specific_provider_name: "koalafi",
  app_id: "13cc6abd-dc86-41a8-b4b5-56f51e96844a",
  approve_status: "approved",
  approved_amount: 3000.0,
  lease_app_id: "39543",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: "12",
      payment_period: "12",
      payment_period_rate: "34.73",
      buyout: false,
      buyout_rate: null,
      initial_payment: 0,
      first_payment: 0,
      approved_amount: "3000",
    },
  ],
  available: true,
  payment_schedule: "weekly",
  taxes: 0.4e2,
  full_taxes: 0.4e2,
  order_id: "eb261aa5-8b7d-4f06-ba6f-07a2d91f1072",
};

export const kornerstoneOffer = {
  provider: "kornerstone",
  specific_provider_name: "kornerstone",
  app_id: "e006ad64-bac9-4c49-8162-0840fcf464ba",
  approve_status: "approved",
  approved_amount: 4000.0,
  lease_app_id: "64e397e79d621ba3a08de522",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: "64e397e79d621ba3a08de522",
      payment_period_rate: 120.66,
      payment_period: 16,
      payment_schedule: "weekly",
      buyout: false,
      buyout_rate: null,
      first_payment: 5.0,
      initial_payment: 5.0,
      contractUrl: "https://utilityapistg.kornerstonecredit.com/uk80EdaX",
      approved_amount: 1000.0,
    },
  ],
};

export const progressiveOffer =  {
  provider: "progressive",
  specific_provider_name: "progressive",
  app_id: "3fe452a9-cb5f-43da-8154-660781e80a5c",
  approve_status: "approved",
  available: true,
  approved_amount: 1000,
  lease_app_id: "19196592",
  is_finished: false,
  options: [
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 1,
      payment_period_rate: 1014.9,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 2,
      payment_period_rate: 507.45,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 3,
      payment_period_rate: 338.3,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 4,
      payment_period_rate: 345.95,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 5,
      payment_period_rate: 297.84,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 6,
      payment_period_rate: 265.77,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 7,
      payment_period_rate: 233.69,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 8,
      payment_period_rate: 218.6,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 9,
      payment_period_rate: 206.68,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 10,
      payment_period_rate: 197.04,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 11,
      payment_period_rate: 189.07,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    },
    {
      type: "Lease to Own",
      offer_id: "19196592",
      payment_period: 12,
      payment_period_rate: 183.4,
      buyout: false,
      buyout_rate: null,
      initial_payment: 49,
      first_payment: 0,
      payment_schedule: 'biWeekly',
    }
  ],
  payment_schedule: "monthly",
  taxes: 0,
  full_taxes: "40.0"
}
export const applicationAgreementStub = {
  provider: "snap",
  contract:
    '<!DOCTYPE html>\n<html>\n\t<head>\n\t\t<meta charset="utf-8">\n\t\t<style type="text/css">\n\t\t\tbody {background-color:lightgray; text-align:center;}\n\t\t\t.qoppa_pageborder {fill:white;}\n\t\t\t#qoppa_document {padding:5px; margin:auto;}\n\t\t</style>\n\t</head>\n\t<body>hello</body> \n </html>\n',
  offers: [snapOffer],
};

export const recommendationsResponseStub = {
  item_id: 8159,
  quantity: 4,
  discount_required: false,
  recommendations: [
    {
      id: 178564,
      description: "Yokohama - Geolandar A/T G015 - 110101636",
      price: "371.97",
      category: "Tire/Wheel/Accesory",
      brand: "Yokohama",
      model: "Geolandar A/T G015",
      sku: "14790-110101636",
      image:
        "https://d19pbi8vkdue6m.cloudfront.net/tires/Yokohama/geolander_at_g015.png",
      variant_id: 178597,
      additional_benefits: {
        mile_warranty: "",
        free_assistance: 'Free Roadside Assistance',
        free_protection: 'Free Road Hazard Protection',
        fuel_efficient: 'Fuel Efficient Tire',
        flat_tire: 'Run Flat Tire'
      },
      specifications: {
        road_condition: "All Season",
        sidewall: "Outlined White Letters",
        tread_type: "All Terrain",
        size_desc: "LT315/75R16 127R E",
        speed_rating: "R",
        load_index: "127",
        diameter: "16",
        rim_width_range: "8 - 11",
      },
    },
    {
      id: 178669,
      description: "Yokohama - GEOLANDAR X-AT - 110116057",
      price: "335.99",
      category: "Tire/Wheel/Accesory",
      brand: "Yokohama",
      model: "GEOLANDAR X-AT",
      sku: "GEOXAT-110116057",
      image:
        "https://d19pbi8vkdue6m.cloudfront.net/tires/Yokohama/geolander_x-at.png",
      variant_id: 178702,
      additional_benefits: {
        mile_warranty: "70,000 Mile Warranty",
        free_assistance: 'Free Roadside Assistance',
        free_protection: 'Free Road Hazard Protection',
        fuel_efficient: 'Fuel Efficient Tire',
        flat_tire: 'Run Flat Tire'
      },
      specifications: {
        road_condition: "All Season",
        sidewall: "Blackwall",
        tread_type: "All Terrain",
        size_desc: "LT315/75R16 127/124Q E",
        speed_rating: "Q",
        load_index: "127",
        diameter: "16",
        rim_width_range: "8 - 10.5",
      },
    },
  ],
};

export const applicationResponseStub = {
  request_id: null,
  amount: 1000,
  updated: false,
  additionals_provided: false,
  // CHANGE FOR DESIGN
  // offers: [kornerstoneOffer, snapOfferUnavailable],
  offers: [katapultOffer, koalafiLeOffer, affOffer],
  // offers: [koalafiLeOffer,uownOffer, affOffer, kornerstoneOffer, snapOfferUnavailable],
  recommendations: recommendationsResponseStub,
};

export const defaultSelectedOffer = {
  provider: "paytomorrow",
  specific_provider_name: "paytomorrow",
  payment_schedule: "monthly",
  app_id: 12,
  offer_id: 21,
  payment_period: 24,
  payment_period_rate: 105,
  first_period: 12,
  first_period_rate: 100,
  last_period: 12,
  last_period_rate: 102,
  buyout: true,
  buyout_rate: 11,
  initial_payment: 34,
};

export const defaultAdditionalInfoData = {
  bankAccount: {
    routing: null,
    account: null,
    type: null,
  },
  id_document: {
    type: null,
    state: null,
    number: null,
    expiration: null,
  },
};

const baseResidence = {
  country: "US",
  street: "1224 Lala Lane",
  city: "Cincinnati",
  state: "OH",
  zip: "45239",
};
const okinusResidence = {
  city: "Hartford",
  zip: "06106",
  street: "502 Broad St",
  state: "CT",
  country: "US",
};

export const userDefaultData = {
  orderId: `ORDER${new Date().getTime()}`,
  visitId: `VISIT${new Date().getTime()}`,
  shipping: 0,
  items: [
    {
      description: "Rose Gold iPhone Xs 256GB",
      quantity: 1,
      price: 1000,
      category: "123",
      brand: "Apple",
      model: "iPhone Xs Rose Gold",
      sku: "1111",
      taxClass: "123",
      taxAmount: 10,
    },
  ],
  personalInfo: {
    email: `valusha${new Date().getTime()}@flexshopper.com`,
    firstName: `Val${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")}`,
    lastName: `Grin${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")}`,
  },
  residenceInfo: okinusResidence,
};

export const genesisSelectedOffer = {
  offer_id: 0,
  app_id: 12,
  payment_period_rate: 103,
  type: "Credit Line",
  provider: "genesis",
  specific_provider_name: "genesis",
};

export const paytomorrowSelectedOffer = {
  offer_id: 21,
  app_id: 12,
  payment_period: 12,
  payment_period_rate: 103,
  first_period: 3,
  first_period_rate: 98,
  last_period: 9,
  last_period_rate: 100,
  buyout: true,
  buyout_rate: 23,
  type: "Lease to Loan",
  initial_payment: 78,
  contractUrl:
    "https://ecom-staging.paytomorrow.com/esign/leaseloan?app=72d82460-8551-4d9d-95fd-e1e67648f364&auth=c974930e-2c7b-4fe4-857c-ac8b31048b00&back_button=false&cancellable=false",
  contract: null,
  provider: "paytomorrow",
  specific_provider_name: "paytomorrow",
};

export const greenWaveSelectedOffer = {
  offer_id: 21,
  app_id: 12,
  payment_period: 12,
  payment_period_rate: 103,
  first_period: 3,
  first_period_rate: 98,
  last_period: 9,
  last_period_rate: 100,
  buyout: true,
  buyout_rate: 23,
  type: "Lease to Loan",
  initial_payment: 78,
  contractUrl:
    "https://ecom-staging.paytomorrow.com/esign/leaseloan?app=72d82460-8551-4d9d-95fd-e1e67648f364&auth=c974930e-2c7b-4fe4-857c-ac8b31048b00&back_button=false&cancellable=false",
  contract: null,
  provider: "paytomorrow",
  specific_provider_name: "greenwave",
};

export const flexshopperSelectedOffer = {
  provider: "flexshopper",
  specific_provider_name: "flexshopper",
  payment_schedule: "weekly",
  app_id: 12,
  offer_id: 21,
  payment_period: 12,
  payment_period_rate: 105,
  buyout: false,
  initial_payment: 34,
  contract:
    "PGgxPllvdSBjYW4gZWRpdCB0aGlzIGRlbW8gdGV4dCE8L2gxPgo8aDI+SG93IHRvIHVzZSB0aGUgZWRpdG9yOjwvaDI+CjxwPlBhc3RlIHlvdXIgZG9jdW1lbnRzIGluIHRoZSB2aXN1YWwgZWRpdG9yIG9uIHRoZSBsZWZ0IG9yIHlvdXIgSFRNTCBjb2RlIGluIHRoZSBzb3VyY2UgZWRpdG9yIGluIHRoZSByaWdodC4gPGJyIC8+RWRpdCBhbnkgb2YgdGhlIHR3byBhcmVhcyBhbmQgc2VlIHRoZSBvdGhlciBjaGFuZ2luZyBpbiByZWFsIHRpbWUuJm5ic3A7PC9wPgo8cD5DbGljayB0aGUgQ2xlYW4gYnV0dG9uIHRvIGNsZWFuIHlvdXIgc291cmNlIGNvZGUuPC9wPgo8aDI+U29tZSB1c2VmdWwgZmVhdHVyZXM6PC9oMj4=",
  data: {
    id: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJxdWVzdGlvblNldElkIjoiMTk1NzY0MzE4IiwidHJhbnNhY3Rpb25JZCI6IjMxMDAwMzc2MzM5Nzg4IiwiaWF0IjoxNTg5ODgzMzk3fQ.2p9SVnBXNzxbm_Key0jA53xM7SAp56wozcJjXqNtoZY",
    questions: [
      {
        id: "2513264468",
        label:
          "Which of the following corporations have you ever been associated with?",
        choices: [
          {
            id: "3475349038",
            label: "Al State Insurance",
          },
          {
            id: "3475349048",
            label: "E-tron Technologies",
          },
          {
            id: "3475349058",
            label: "Future Looks Of Triad",
          },
          {
            id: "3475349068",
            label: "Pri Super Blue Technology",
          },
          {
            id: "3475349078",
            label: "Travelers Insurnace",
          },
          {
            id: "3475349088",
            label: "None of the above",
          },
        ],
      },
    ],
  },
};

export const snapSelectedOffer = {
  app_id: 12,
  approve_status: "approved",
  approved_amount: 5000,
  available: true,
  is_finished: false,
  lease_app_id: "2205140004TX",
  options: [
    {
      due_today_text: 1.08,
      initial_payment: 1.08,
      maxLoan: 5000,
      offer_id: null,
      optionName: "B",
      payment_period: 10,
      payment_period_rate: 118.36,
      row_data: {
        paymentPlanSummary: {
          lastPaymentAmount: "21.82",
          paymentAmount: "22.03",
          leaseTermInMonths: "12",
          processingFee: "10.00",
          leaseAmount: "250.03",
          leaseCost: "322.54",
          leaseTotalAmount: "572.57",
          paymentFrequency: "BI_WEEKLY",
          currencyCode: "USD",
          // stbs: false,
          stbs: true,
          stbsSalesTaxPercent: "0.00",
          cpoDate: "2021-06-01",
          cpoAmount: "260.03",
          cpoNumberOfPayments: "6",
          cpoPaymentsAmount: "37.15",
          cpoLastPaymentAmount: "37.13",
          cpoLeaseCost: "0.00",
          initialPayment: "0.00",
          totalNumberOfPayments: "26",
          numberOfPaymentsWithSameAmount: "25",
          lastPaymentDate: "2022-04-01",
        },
      },
    },
  ],
  payment_schedule: "monthly",
  provider: "snap",
  specific_provider_name: "snap",
};
