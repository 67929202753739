import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { required } from "utils/validations";
import { change, Field, reduxForm } from "redux-form";
import URLS from "utils/import_urls";
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import {
  appRequestSelector,
  trendingsVisitID,
  subscribingDataSelector,
} from "../../selectors";
import { createStructuredSelector } from "reselect";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { capitalizeFirstletter, paymentFrequency } from "utils/helpers";

import "./styles.scss";
import { RadioField } from "../../../../components/Fields";
import OfferBlock from "../../../../components/DataDisplay/OfferBlock";

class ProgressiveOffer extends Component {
  state = {
    loading: false,
    options: [],
    selectedOption: {
      provider: "",
      type: "",
      offer_id: null,
      payment_period: null,
      payment_period_rate: null,
      first_period: null,
      first_period_rate: null,
      last_period: null,
      last_period_rate: null,
      buyout: false,
      buyout_rate: null,
      initial_payment: null,
    },
  };
  componentDidMount = () => {
    const { offer } = this.props;
    this.setState({
      options: offer.options,
    });
    const defaultSelected = offer.options[offer.options.length - 1];
    if (offer.options.length > 1) {
      this.props.dispatch(
        change(
          "form",
          "paymentPeriod",
          defaultSelected.payment_period.toString()
        )
      );
    }
    this.setSelected(defaultSelected);
  };

  setSelected = (option) => {
    const { offer } = this.props;
    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      specific_provider_name: offer.specific_provider_name,
      payment_schedule: offer.payment_schedule,
      cart_total: offer.cart_total,
      approved_amount: offer.approved_amount,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={this.paymentPeriodOptions()}
        customChange={this.setPaymentPeriod}
      />
    );
  };

  setPaymentPeriod = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.payment_period === parseInt(value)
    );

    this.setSelected(option);
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `${option.payment_period.toString()} Months`,
        key: option.payment_period.toString(),
      };
    });
  };

  submitForm = () => {
    const { selectOffer, offer, logContinueEvent } = this.props;
    const { selectedOption } = this.state;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(
      token,
      "select_provider",
      "progressive",
      visitID
    );

    logContinueEvent();

    this.setState({ loading: true });
    selectOffer({
      selectedOffer: selectedOption,
      body: { offer_id: selectedOption.offer_id },
    });
  };

  renderHeaderPaymentPeriod = () => {
    const { selectedOption } = this.state;

    return `${selectedOption.payment_period} Months`;
  };

  retailDisclaimer = () => {
    return (
      <div className="step-tooltip-block font-italic">
        <div className="pad-bottom">
          Standard agreement offers 12 months to ownership. 90-day and other
          early purchase options cost more than the retailer's cash price
          (except 3-month option in CA). To purchase early or to cancel lease
          you must call 877-898-1970. Retailer cannot activate early purchase
          options.
        </div>
        <div>
          This tool only provides an estimate, excluding sales tax. Actual terms
          will be in the lease agreement. The transaction is lease-to-own or a
          rental- or lease-purchase agreement provided by Prog Leasing, LLC, or
          its affiliates. The standard lease-to-own agreement offers 12 months
          to ownership and could cost more than double the cash price. Leasing
          available on select items at participating locations only. Not
          available in MN, NJ, VT, WI, WY.
        </div>
      </div>
    );
  };

  renderFootter = () => {
    return (
      <BuyoutInfo
        title={"Early Purchase Option:"}
        text={'90-day and other early purchase options cost more than the retail cash price (except 3-month option in CA). To cancel lease or purchase early call 877-898-1970.'}
      />
    );
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, valid, available, isExpanded, offer, appRequest } =
      this.props;

    if (!selectedOption) {
      return "";
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          showPlanTypeTooltip
          available={available}
          isExpanded={isExpanded}
          dueToday={{ amount: 1, info: "(plus applicable taxes)" }}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${this.renderHeaderPaymentPeriod()}`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (
          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="progressive-offer application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption)} />
            </div>
            {this.retailDisclaimer()}
            {this.renderFootter()}

            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!available || !valid}
              type="submit"
            >
              Continue with{" "}
              {capitalizeFirstletter(offer.specific_provider_name)}
            </BlueBtn>
          </form>
        )}
      </div>
    );
  }
}

ProgressiveOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

ProgressiveOffer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  appRequest: appRequestSelector(),
  visitID: trendingsVisitID(),
  orderData: subscribingDataSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: (value) => dispatch(nextStep(value)),
  };
};

ProgressiveOffer = connect(
  mapStateToProps,
  mapDistpatchToProps
)(ProgressiveOffer);

ProgressiveOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ProgressiveOffer);

export default ProgressiveOffer;
