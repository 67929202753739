import React from "react";
import { Field } from "redux-form";
import { required } from "utils/validations";
import PaypairTermsofService from "assets/docs/PaypairTermsofService.pdf";
import PaypairPrivacyPolicy from "assets/docs/PaypairPrivacyPolicy.pdf";
import PaypairPatriotActDisclosure from "assets/docs/PaypairPatriotActDisclosure.pdf";
import { CustomCheckbox } from "components/Controls";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const renderAgreementCheckbox = ({ input, meta }) => {
  return <CustomCheckbox meta={meta} {...input} />;
};

const LinkTooltip = ({ title, children }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        sx={{
          tooltip: {
            backgroundColor: "#F8F8F8",
            color: "#000",
            fontSize: "12px",
            fontFamily: "OpenSans-Regular",
            padding: "10px",
            fontWeight: "normal",
          },
        }}
        className="link"
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        interactive
        disableTouchListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 200 }}
        title={
          title ? (
            <div
              style={{ height: "100px", minHeight: 300, overflowY: "auto" }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : (
            ""
          )
        }
      >
        <span onClick={handleTooltipOpen}>{children}</span>
      </Tooltip>
    </ClickAwayListener>
  );
};

export const renderPrivacyPolicy = (token, providers, agreements) => {
  const affProvider = providers && providers.includes("aff");

  return (
    <div className="privacy-policy">
      <div className="privacy-policy-agreement flex flex__align--center">
        <div className="triangle">▶</div>
        <Field
          name="isAgreementChecked"
          validate={[required]}
          component={renderAgreementCheckbox}
        />
        <div className="privacy-policy-agreement__label">
          By checking this box, you agree to the disclosure below.
        </div>
      </div>

      {affProvider && agreements && (
        <div className="privacy-policy-agreement tcpa-agreement flex flex__align--center margin-left">
          <Field name="agreeTcpa" component={renderAgreementCheckbox} />
          <div className="privacy-policy-agreement__label">
            By checking this box, you agree to{" "}
            <LinkTooltip title={agreements.tcpa && agreements.tcpa.html}>
              American First Finance Optional consent to marketing emails, calls
              and texts
            </LinkTooltip>
          </div>
        </div>
      )}
      <div className="privacy-policy-text privacy-policy-mobile">
        By clicking the “See My Payment Plan Options” button, I am submitting an
        application for financing or leasing (“Application”), and I agree to the{" "}
        <a href={PaypairTermsofService} className="link" target="_blank">
          Terms of Use,
        </a>{" "}
        <a href={PaypairPrivacyPolicy} className="link" target="_blank">
          Privacy Policy,
        </a>{" "}
        and{" "}
        <a href={PaypairPatriotActDisclosure} className="link" target="_blank">
          Patriot Act Disclosure;
        </a>{" "}
        all of the information I have provided on this Application is true and
        correct. I agree that I am furnishing my Application to Tire Agent
        (“Merchant”), and I give Merchant permission to share my personal
        information, including my Application, with its service provider,{" "}
        <span className="font-bold">Paypair</span>, for further sharing with
        lenders, financiers, or lease providers who may be able to provide
        financing or lease options to me for goods and services from Merchant or
        other merchants that implement the{" "}
        <span className="font-bold">Paypair</span> solution, and for marketing
        products and services to me. I authorize Merchant,{" "}
        <span className="font-bold">Paypair</span>, and other lenders,
        financiers, or lease providers to obtain information from my credit
        report or personal information from consumer credit agencies to conduct
        a pre-qualification for all my financing options. Lenders include but
        are not limited to West Creek Financial, Inc. dba Koalafi on its own or
        on behalf of The Bank of Missouri. By continuing, you also agree to
        Koalafi’s{" "}
        <a
          href="https://apply.koalafi.com/electronic-communications?support=lto"
          className="link"
          target="_blank"
        >
          esign agreement
        </a>{" "}
        and{" "}
        <a
          href="https://koalafi.com/privacy-policy"
          className="link"
          target="_blank"
        >
          privacy policy
        </a>
        , and Koalafi’s{" "}
        <a
          href="https://apply.koalafi.com/terms-and-conditions"
          className="link"
          target="_blank"
        >
          terms and conditions
        </a>
        .{" "}
        {affProvider && agreements && (
          <React.Fragment>
            By continuing you also agree to American First Finance’s{" "}
            <LinkTooltip
              title={agreements.paymentAuth && agreements.paymentAuth.html}
            >
              esign agreement
            </LinkTooltip>
            ,{" "}
            <a
              href={agreements.privacyPolicy && agreements.privacyPolicy.file}
              className="link"
              target="_blank"
            >
              privacy policy
            </a>
            , and{" "}
            <LinkTooltip title={agreements.terms && agreements.terms.html}>
              terms and conditions
            </LinkTooltip>
            .
          </React.Fragment>
        )}
        <div>
          You agree to Progressive Leasing’s{" "}
          <a
            href="https://progleasing.com/privacy"
            className="link"
            target="_blank"
          >
            Privacy
          </a>{" "}
          and{" "}
          <a
            href="https://progleasing.com/arbitration-clause"
            className="link"
            target="_blank"
          >
            Arbitration Provision
          </a>{" "}
          that will govern your transaction. Categories of personal information
          are collected and information about you from consumer reporting
          agencies is used to determine pre-qualification eligibility. Share my
          information with Progressive Leasing.
        </div>
      </div>
    </div>
  );
};
