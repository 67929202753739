import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer } from "../../actions";
import { mergeRight } from "ramda";
import { OfferView, BuyoutInfo } from "components/DataDisplay";
import { reduxForm, Field, change } from "redux-form";
import PropTypes from "prop-types";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { appRequestSelector, trendingsVisitID } from "../../selectors";
import { createStructuredSelector } from "reselect";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { RadioField } from "components/Fields";
import { details } from "./details";
import { paymentFrequency } from "utils/helpers";

import "./styles.scss";

class KoalafiOffer extends Component {
  state = {
    loading: false,
    selectedOffer: null,
    detailsShown: false,
    options: [],
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setState({
      options: offer.options,
    });
    const defaultSelected = offer.options[offer.options.length - 1];
    if (offer.options.length > 1) {
      this.props.dispatch(
        change(
          "form",
          "paymentPeriod",
          defaultSelected.payment_period.toString()
        )
      );
    }
    this.setSelected(defaultSelected);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      order_id: offer.order_id,
      offer_id: option.offer_id,
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      approved_amount: option.approved_amount,
      specific_provider_name: offer.provider,
      cart_total: offer.cart_total,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  renderEarlyOptions = () => {
    return (
      <div>
        <span class='options-header'>Early Ownership Options</span>
        <BuyoutInfo
          title={'Early Purchase Option:'}
          text={'Pay off your lease within 90 days and only pay the cash amount plus taxes (varies by state, other fees and charges may apply).'}
        />
        <div className='second-buyoutinfo'>
          <BuyoutInfo
            title={'Early Purchase Savings:'}
            text={'Save money when you pay your lease off early.'}
            footnote={''}
          />
        </div>
      </div>
    )
  }

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <div className="agreemnet-block__label">
            <span>Subject to additional identity verification questions.</span>
          </div>
        </div>
      </div>
    );
  };

  renderLoanInfo = () => {
    return (
      <div>
        <BuyoutInfo
          title={'Promotional Financing Available'}
          text={'Pay 0% interest if you pay off your loan balance in full within the first 3 months*'}
        />
        <div className="loan-info">
          <span className="font-bold">Important Information:</span> We do perform a credit check when you agree to the Koalafi terms.
          <br/>
          <br/>
          <span>*Offer applicable to loan product only. For qualifying
            customers only. You will not be charged interest if you pay
            your loan balance in full within the promotional period.
            Promotional period may be 3 months or longer depending
            on the terms and may not be available on all loan products.
            Must make all payments on time and be in good standing to
            remain eligible for the promotional period. Interest may be
            charged at an Annual Percentage Rate (APR) of up to 36%
            beginning on the purchase date. The APR may vary and is
            subject to increase after consummation. The APR is the
            cost of credit as a yearly rate, reflects your interest rate,
            and may include an origination fee up to 10% of your loan
            amount. Taxes, fees, and other charges may apply. Details
            may vary from state to state. See agreement for details.
            Offer applicable to loan product only. Loans are issues by
            The Bank of Missouri, serviced by Koalafi.
          </span>
        </div>
      </div>
    )
  }

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={this.paymentPeriodOptions()}
        customChange={this.setPaymentPeriod}
      />
    );
  };

  setPaymentPeriod = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.payment_period === value
    );

    this.setSelected(option);
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `${option.payment_period.toString()} Months`,
        key: option.payment_period.toString(),
      };
    });
  };

  renderPaymentPeriodBlock = () => {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if (offer.options.length === 1) {
      return <OfferBlock
        row={[{
          title: 'Payment Period',
          value: `${selectedOption.payment_period} Months`
        }]}
      />
    } else {
      return (<div className="offer-block offer-block--100">
        <div className="offer-item offer-item__column">
          <div className="offer-item__title">Payment Period:</div>
          <Field
            name="paymentPeriod"
            component={this.renderPaymentPeriodField}
          />
        </div>
      </div>)
    }
  }

  submitForm = (values) => {
    let { selectedOption } = this.state;
    const { selectOffer, setSelectedOffer, logContinueEvent } = this.props;

    if (selectedOption.type === 'Lease to Own'){
      selectedOption.specific_provider_name = 'koalafiLe'
    } else {
      selectedOption.specific_provider_name = 'koalafiLo'
    }
    logContinueEvent()
    setSelectedOffer(selectedOption);
    this.setState({ loading: true })
    selectOffer({ selectedOffer: selectedOption });
  };


  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, valid, available, isExpanded, offer } = this.props;

    if (!selectedOption) {
      return '';
    }

    const isLease = (selectedOption.type === 'Lease to Own')
    const dueToday = isLease ? 0.01 : 0
    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={dueToday}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (

          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="koalafi-offer application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption, details())} />
              {this.renderPaymentPeriodBlock()}
            </div>

            { isLease ? (
              <div>
                {this.renderEarlyOptions()}
                {this.renderLeaseAgreement()}
              </div>
            ) : (
              this.renderLoanInfo()
            )}

            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!available}
              type="submit"
            >
              Continue with Koalafi
            </BlueBtn>
          </form>

        )}
      </div>
    );
  }
}

KoalafiOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool
};

KoalafiOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  appRequest: appRequestSelector(),
  visitID: trendingsVisitID()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    setSelectedOffer: value => dispatch(setSelectedOffer(value))
  };
};

KoalafiOffer = connect(mapStateToProps, mapDistpatchToProps)(KoalafiOffer);

KoalafiOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(KoalafiOffer);

export default KoalafiOffer;
