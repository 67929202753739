import { ofType, combineEpics } from 'redux-observable';
import { withLatestFrom, flatMap, catchError } from 'rxjs/operators';
import {of, from, EMPTY, merge} from 'rxjs';


import { ApplicationRequestAPI } from 'api/application_request';

import { ApplicationAPI } from 'api/application';
import { UsersAPI } from 'api/users';

import * as ApplicationRequestActions from './actions';
import { ActionTypes } from './actionTypes';

import { checkDiscount, maxApprove } from './discount_helper'
import { addMetrix } from './metrix_helper'

import * as WidgetActions from 'containers/EmbeddableWidget/actions';

const init = (action$, state$) => action$.pipe(
  ofType(ActionTypes.init),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    if (state.widget.inIframe) {
      return [];
    }
    return [
      ApplicationRequestActions.initUserData(action.payload)
    ]
  }),
);

const retrieveVisitorId = () => {
  return document.cookie.replace(/(?:(?:^|.*;\s*)__attentive_id\s*=\s*([^;]*).*$)|^.*$/, "$1");
}

const subscribeRequest = (state, status = 'approved') => {
  if(!state.form.form.values.isAgreed) {
    return false;
  }
  if(process.env.REACT_APP_PAYPAIR_ENV !== 'production'){
    return false;
  }
  return UsersAPI.subscribe(state.widget.key, {
    "phone": state.leaseForm.applicationRequest.personalInfo.phone,
    "visitor_id": retrieveVisitorId(),
    "status": status
  })
}

const subscribe = (state, data) => {
  if (data.offers.filter(o => o.approve_status == 'declined').length == data.offers.length) {
    return subscribeRequest(state, "declined")
  }
  if (data.offers.filter(o => o.approve_status == 'approved').length > 0) {
    return subscribeRequest(state, "approved")
  }
}


const initApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.initApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const requestDate = addMetrix(state)
    return from(ApplicationRequestAPI.init(
      state.widget.key,
      {application: requestDate}
    ))
      .pipe(
        flatMap(data => {
          if (data.errors) {
            return of(ApplicationRequestActions.initApplicationFailed())
          }

          if (requestDate.provider_name == 'flexshopper') {
            data.offers[0].additional_info_data = requestDate.additional_info_data;
          }

          const discountOffers = checkDiscount(data, state);

          if (discountOffers.offers.length > 0 && !requestDate.promoApplied) {
            return [
              ApplicationRequestActions.applyDiscount({data, discountOffers})
            ]
          }
          subscribe( state, data );

          const maxApproveValue = maxApprove(data)
          window.parent.postMessage({type: "maxAmount", amount: maxApproveValue}, '*')
          return [
            ApplicationRequestActions.setMaxApprove(maxApproveValue),
            ApplicationRequestActions.initApplicationSuccess({data}),
            ApplicationRequestActions.processApplicationResponse(data)
          ]
        }),
        catchError(err => {
          return of(ApplicationRequestActions.initApplicationFailed()
          )}),
      )
  }),
);

const applyDiscount = (action$, state$) => action$.pipe(
  ofType(ActionTypes.applyDiscount),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    return from(ApplicationRequestAPI.discount(
      state.widget.key,
      state.leaseForm.applicationRequest.orderId,
      action.payload.discountOffers.percentage
    ))
      .pipe(
        flatMap((data) => {
          const items = data.items
          return [
            ApplicationRequestActions.updateCart(items),
            ApplicationRequestActions.updateWithDiscount({data: action.payload.discountOffers.offers})
          ]
        }),
        catchError(err => {
          return of(ApplicationRequestActions.processApplicationResponse(action.payload.data)
          )})
      )
  })
)

const updateWithDiscount = (action$, state$) => action$.pipe(
  ofType(ActionTypes.updateWithDiscount),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    return from(ApplicationRequestAPI.updateWithDiscount(
      state.widget.key,
      state.leaseForm.requestId,
      {application: state.leaseForm.applicationRequest, providers: state.leaseForm.discountProviders}
    ))
      .pipe(
        flatMap((data) => {
          if (data.errors) {
            return of(ApplicationRequestActions.initApplicationFailed())
          }

          if (state.leaseForm.applicationRequest.provider_name == 'flexshopper') {
            data.offers[0].additional_info_data = state.leaseForm.applicationRequest.additional_info_data;
          }

          subscribe( state, data );

          return [
            ApplicationRequestActions.initApplicationSuccess({data}),
            ApplicationRequestActions.processApplicationResponse(data)
          ]
        }),
        catchError(err => {
          return of(ApplicationRequestActions.processApplicationResponse(action.payload.data)
          )})
      )
  })
)

const editCart = (action$, state$) => action$.pipe(
  ofType(ActionTypes.editCart),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    return from(ApplicationRequestAPI.editCart(
      state.widget.key,
      state.leaseForm.applicationRequest.orderId,
      action.payload
    ))
      .pipe(
        flatMap((data) => {
          if (data.errors || data.error) {
            return of(ApplicationRequestActions.initApplicationFailed())
          }

          const items = data.items
          return [
            ApplicationRequestActions.updateCart(items),
            ApplicationRequestActions.updateWithRecommendations(),
          ];
        }),
        catchError(err => {
          return of(ApplicationRequestActions.processApplicationResponse(action.payload.data));
        })
      )
  })
)

const updateWithRecommendation = (action$, state$) => action$.pipe(
  ofType(ActionTypes.updateWithRecommendations),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    return from(ApplicationRequestAPI.update_cart(
      state.widget.key,
      state.leaseForm.requestId,
      {application: state.leaseForm.applicationRequest}
    ))
      .pipe(
        flatMap((data) => {
          if (data.errors) {
            return of(ApplicationRequestActions.initApplicationFailed())
          }

          subscribe( state, data );

          return [
            ApplicationRequestActions.initApplicationSuccess({data}),
            ApplicationRequestActions.processApplicationResponse(data)
          ]
        }),
        catchError(err => {
          return of(ApplicationRequestActions.processApplicationResponse(action.payload.data)
          )})
      )
  })
)

const getRecommendations = (action$, state$) => action$.pipe(
  ofType(ActionTypes.getRecommendations),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    return from(ApplicationRequestAPI.recommendations(
      state.widget.key,
      state.leaseForm.applicationRequest.orderId,
      action.payload.maxApproval
    ))
      .pipe(
        flatMap((data) => {
          return [
            ApplicationRequestActions.setRecommendations(data),
            ApplicationRequestActions.processApplicationResponse(state.leaseForm.applicationResponse),
          ];
        }),
        catchError(err => {
          return of(ApplicationRequestActions.processApplicationResponse(state.leaseForm.applicationResponse)
          )})
      )
  })
);

const processApplicationResponse = (action$, state$) => action$.pipe(
  ofType(ActionTypes.processApplicationResponse),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const data = action.payload

    if (!data.offers || data.offers.length === 0) {
      return of(ApplicationRequestActions.nextStep({step: 'nobodyApprovedStep'}))
    }

    const approved_not_finished = data.offers.filter(o => o.approve_status === 'approved' && o.is_finished === false);
    const need_additionals = data.offers.filter(o => o.approve_status === 'need_additionals');
    const affiliated_provider = state.leaseForm.applicationRequest.provider_name;
    const finished = data.offers.find(o => o.approve_status === 'approved' && o.is_finished === true);

    if (finished) {
      return of(ApplicationRequestActions.nextStep({
        step: 'alreadyCompletedStep',
        data: { app: finished }
      }))
    }

    if (approved_not_finished.length === 0) {
      if (need_additionals.length > 0 && affiliated_provider == 'anybody') {
        return of(ApplicationRequestActions.nextStep({step:'additionalInfoStep'}))
      }

      return of(ApplicationRequestActions.nextStep({step:'nobodyApprovedStep'}))
    }
    const prequalification = state.leaseForm.applicationRequest.prequalification === 'true'

    const availableApplications = data.offers.filter(o => o.approve_status === 'approved' && o.available)
    const notAvailableApplications = data.offers.filter(o => o.approve_status === 'approved' && !o.available)
    const nextStep = prequalification ? 'step3Prequalification' : 'form3'

    if (
      availableApplications.length === 0 &&
      notAvailableApplications.length > 0 && !state.leaseForm.recommendationsApplied
    ) {
      const maxApprovedAmount = Math.max(...notAvailableApplications.map(app => app.approved_amount));
      const highestApprovals = notAvailableApplications.filter(app => app.approved_amount === maxApprovedAmount);

      // Filter out the lenders for which you want to apply specific logic (subtracting tax)
      const regularLenders = ['uown', 'ownlease', 'koalafi', 'aff', 'flexshopper'];
      const regularLendersApproval = highestApprovals.filter(app => regularLenders.includes(app.provider));

      let maxApproval;

      if (regularLendersApproval.length > 0) {
        maxApproval = regularLendersApproval[0].approved_amount;
      } else {
        maxApproval = highestApprovals[0].approved_amount - highestApprovals[0].full_taxes;
      }

      return of(ApplicationRequestActions.getRecommendations({maxApproval}));
    }

    return of(ApplicationRequestActions.nextStep({step: nextStep}))
  })
);

const continueInitApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.continueInitApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.continue(
      state.widget.key,
      state.leaseForm.requestId,
      {application: state.leaseForm.applicationRequest}))
      .pipe(
        flatMap(data => {
          if (data.errors) {
            return of(ApplicationRequestActions.initApplicationFailed())
          }

          const discountOffers = checkDiscount(data, state);
          if (discountOffers.offers.length > 0 && !state.leaseForm.applicationRequest.promoApplied) {
            return [
              ApplicationRequestActions.applyDiscount({data, discountOffers})
            ]
          }
          subscribe( state, data );

          return [
            ApplicationRequestActions.continueInitApplicationSuccess({data}),
            ApplicationRequestActions.processApplicationResponse(data)
          ]

        }),
        catchError(err => {
          return of(ApplicationRequestActions.initApplicationFailed()
          )}),
      )
  }),
);


// const acceptOffer = (action$, state$) => action$.pipe(
//   ofType(ActionTypes.acceptOffer),
//   withLatestFrom(state$),
//   flatMap(([ action, state ]) => {
//     return [
//       ApplicationRequestActions.acceptOfferRequest(state.leaseForm.selectedOffer)
//     ]
//   }),
// )

// const acceptOfferRequest = (action$, state$) => action$.pipe(
//   ofType(ActionTypes.acceptOfferRequest),
//   withLatestFrom(state$),
//   flatMap(([ action, state ]) => {
//     return from(ApplicationAPI.accept_the_offer(
//       state.widget.key,
//       action.payload.app_id,
//       { offer_id: action.payload.offer_id }
//     ))
//     .pipe(
//       flatMap(data => {
//         if (data.errors) {
//           return of(ApplicationRequestActions.acceptOfferFailed())
//         }

//         return of(ApplicationRequestActions.acceptOfferSuccess(data))
//       }),
//       catchError(err => {
//         return of(ApplicationRequestActions.acceptOfferFailed()
//       )}),
//     )
//   }),
// )

/////////////////////////////////////////////////////////////////
// set selected offer to reduser and call accept_the_offer for flexshopper
// should go to confirmationStep
const selectOffer = (action$, state$) => action$.pipe(
  ofType(ActionTypes.selectOffer),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    const selectedOffer = state.leaseForm.selectedOffer;
    const allSelectedOffers = state.leaseForm.allSelectedOffers;
    const requestBody = action.payload.body || action.payload.selectedOffer;

    // Show already opened contract
    if (allSelectedOffers.some(offer => offer.app_id === selectedOffer.app_id)) {
      return of(ApplicationRequestActions.selectOfferSuccess({selectedOffer: selectedOffer, isContractOpened: true}));
    }

    if (selectedOffer.provider === 'snap' && state.leaseForm.activeStep === 'form3') {
      return of(ApplicationRequestActions.nextStep({ step: 'snapLeasePayment', data: selectedOffer }));
    }
    if ((selectedOffer.provider === 'koalafi' && selectedOffer.type === 'Lease to Own') && state.leaseForm.activeStep === 'form3') {
      return of(ApplicationRequestActions.nextStep({ step: 'contractStep', data: selectedOffer }));
    }
    if (selectedOffer.bank_data_is_required) {
      return of(ApplicationRequestActions.nextStep({ step: 'flexshopperBankData', data: selectedOffer }));
    }
    if (selectedOffer.provider === 'progressive') {
      if (state.leaseForm.activeStep === 'form3') {
        return of(ApplicationRequestActions.nextStep({ step: 'progressiveBankData', data: selectedOffer }));
      }
      if (state.leaseForm.activeStep === 'progressiveBankData') {
        return of(ApplicationRequestActions.nextStep({ step: 'progressiveCardData', data: selectedOffer }));
      }
      if (state.leaseForm.activeStep === 'progressiveCardData') {
        return of(ApplicationRequestActions.nextStep({ step: 'progressiveAgreement', data: selectedOffer }));
      }
    }

    return from(ApplicationAPI.accept_the_offer(
      state.widget.key,
      selectedOffer.app_id,
      requestBody || {}
    ))
      .pipe(
        flatMap(data => {
          if (data.errors) {
            return of(ApplicationRequestActions.selectOfferFailed());
          }
          return of(ApplicationRequestActions.selectOfferSuccess({selectedOffer: data, isContractOpened: false}));
        }),
        catchError(err => {
          return of(ApplicationRequestActions.selectOfferFailed());
        })
      )
  }),
);

const selectOfferSuccess = (action$, state$) => action$.pipe(
  ofType(ActionTypes.selectOfferSuccess),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    const selectedOffer = state.leaseForm.selectedOffer;
    const isContractOpened = action.payload.isContractOpened || false;
    let nextStep = 'confirmationStep';

    // handle already opened contract
    if (isContractOpened) {
      nextStep = 'contractStep';
    } else {
      if (['aff', 'acima', 'koalafi', 'ownlease', 'progressive'].includes(selectedOffer.provider) || selectedOffer.specific_provider_name === 'katapult') {
        nextStep = 'contractStep';
      }
      if (selectedOffer.provider === 'snap') {
        nextStep = 'snapConfirmation';
      }
      if (selectedOffer.provider === 'flexshopper') {
        if (selectedOffer.bank_data === 'invalid') {
          return of(ApplicationRequestActions.selectOfferFailed({
            title: 'Thank You For Applying',
            message: 'You did not qualify for a spending limit. Thank you for your interest in FlexShopper. You will be sent an email with an explanation within 30 days.'
          }));
        }
        if (selectedOffer.bank_data_is_required) {
          nextStep = 'flexshopperBankData';
        } else if (selectedOffer.verification_is_required) {
          nextStep = 'verificationQuestionsStep';
        } else {
          nextStep = 'contractStep';
        }
      }
    }
    return of(ApplicationRequestActions.nextStep({ step: nextStep, data: selectedOffer }));
  })
);


// used on confirmation step
// does nothing for paytomorrow
// call accept_the_offer for flexshopper
// go to contract step for paytomorrow

const confirmOffer = (action$, state$) => action$.pipe(
  ofType(ActionTypes.confirmOffer),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const selectedOffer = state.leaseForm.selectedOffer
    return from(ApplicationAPI.accept_the_offer(
      state.widget.key,
      selectedOffer.app_id,
      { offer_id: selectedOffer.offer_id }
    ))
      .pipe(
        flatMap(data => {
          if (data.errors) {
            return of(ApplicationRequestActions.confirmOfferFailed())
          }

          let activeStep = [
            "paytomorrow",
            "okinus",
            "uown",
            "ownlease",
            "kornerstone",
          ].includes(state.leaseForm.selectedOffer.provider)
            ? "contractStep"
            : "confirmationStep";

          return [
            ApplicationRequestActions.confirmOfferSuccess(data),
            ApplicationRequestActions.nextStep({ step: activeStep, data: data })
          ];

        }),
        catchError(err => {
          return of(ApplicationRequestActions.confirmOfferFailed()
          )}),
      )
  }),
);



///////////////////////////////////////

const cancelApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.cancelApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationAPI.cancel(
      state.widget.key,
      state.leaseForm.selectedOffer.app_id,
      {}
    ))
      .pipe(
        flatMap(data => {
          return [];
        }),
        catchError(err => {
          return [];
        })
      )
  })
)

const finishApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.finishApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationAPI.finish(
      state.widget.key,
      state.leaseForm.selectedOffer.app_id,
      action.payload && action.payload.status && {status: action.payload.status}
    ))
      .pipe(
        flatMap(data => {
          if (data.errors) {
            return of(ApplicationRequestActions.finishApplicationFailed())
          }

          let actions = !(action.payload && action.payload.status === 'exited') ? [
            ApplicationRequestActions.finishApplicationSuccess(),
            ApplicationRequestActions.nextStep({step: 'successStep'}),
            WidgetActions.finishFlow()
          ] : [];

          return actions.length > 0 ? from(actions) : EMPTY;
        }),
        catchError(err => {
          return of(ApplicationRequestActions.finishApplicationFailed())
        })
      )
  })
)

const processAuthUserResponse = (action$, state$) => action$.pipe(
  ofType(ActionTypes.processAuthUserResponse),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    // check how it will work on production. Maybe it will be not a string
    const prequalification = state.leaseForm.applicationRequest.prequalification === 'true'
    const data = action.payload.response
    if ((!state.leaseForm.applicationRequest || !state.leaseForm.applicationRequest.personalInfo ||
      !state.leaseForm.applicationRequest.personalInfo.email) && (!prequalification)) {
      return [
        ApplicationRequestActions.selectOfferFailed({error_form: 'wrong_data'})
      ]
    }
    if (!data.request) {
      if (prequalification)
      {
        return [ApplicationRequestActions.nextStep({step: 'prequalification'})]
      }
      return [ApplicationRequestActions.nextStep({step: 'form2'})]
    }

    if (data.can_be_updated) {
      return of(
        ApplicationRequestActions.updateApplication({
          request_id: data.request.id
        })
      )
    }

    if (data.already_completed) {
      if( data.already_completed.provider === 'paytomorrow') {
        return of(ApplicationRequestActions.nextStep({
          step: 'alreadyCompletedStep',
          data: { app: data.already_completed }
        }))
      } else {
        //TODO: !!!!!!!!!!
        return of(
          ApplicationRequestActions.updateCompletedApplication({
            request_id: data.request.id
          })
        )
      }
    }

    if (data.all_declined) {
      return of(ApplicationRequestActions.nextStep({step: 'nobodyApprovedStep'}))
    }

    // init new request
    return of(ApplicationRequestActions.nextStep({step: 'form2'}))
  })
)


const updateApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.updateApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.update(
      state.widget.key,
      action.payload.request_id,
      {application: state.leaseForm.applicationRequest}
    ))
      .pipe(
        flatMap(data => {

          const discountOffers = checkDiscount(data, state);
          if (discountOffers.offers.length > 0 && !state.leaseForm.applicationRequest.promoApplied) {
            return [
              ApplicationRequestActions.applyDiscount({data, discountOffers})
            ]
          }
          return [
            ApplicationRequestActions.initApplicationSuccess({data}),
            ApplicationRequestActions.processApplicationResponse(data)
          ]
        }),
        catchError(err => {
          return of(ApplicationRequestActions.initApplicationFailed()
          )}),
      )
  }),
);

const updateCompletedApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.updateCompletedApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.updateCompletedApplication(
      state.widget.key,
      action.payload.request_id,
      {application: state.leaseForm.applicationRequest}
    ))
      .pipe(
        flatMap(data => {
          if (data.errors) {
            return of(ApplicationRequestActions.initApplicationFailed())
          }
          return [
            ApplicationRequestActions.initApplicationSuccess({data}),
            ApplicationRequestActions.processApplicationResponse(data)
          ]
        }),
        catchError(err => {
          return of(ApplicationRequestActions.initApplicationFailed()
          )}),
      )
  }),
);


// const processUpdateCompletedApplicationResponse = (action$, state$) => action$.pipe(
//   ofType(ActionTypes.processUpdateCompletedApplicationResponse),
//   withLatestFrom(state$),
//   flatMap(([ action, state ]) => {
//     const data = action.payload

//     if (data.offers.length === 0) {
//       return of(ApplicationRequestActions.nextStep({step: 'nobodyApprovedStep'}))
//     }

//     // const approved_not_finished = data.offers.filter(o => o.approve_status === 'approved' && o.is_finished === false);
//     // const need_additionals = data.offers.filter(o => o.approve_status === 'need_additionals');
//     const finished = data.offers.find(o => o.is_finished === true);

//     // if (finished) {
//     //   return of(ApplicationRequestActions.nextStep({
//     //     step: 'alreadyCompletedStep',
//     //     data: { app: finished }
//     //   }))
//     // }

//     // if (approved_not_finished.length > 0) {
//     //   return of(ApplicationRequestActions.nextStep({step: 'form4'}))
//     // }

//     // if (need_additionals.length > 0) {
//     //   return of(ApplicationRequestActions.nextStep({step:'additionalInfoStep'}))
//     // }

//     return of(ApplicationRequestActions.nextStep({step:'nobodyApprovedStep'}))
//   })
// );


export default combineEpics(
  applyDiscount,
  init,
  initApplication,
  selectOffer,
  selectOfferSuccess,
  confirmOffer,
  cancelApplication,
  finishApplication,
  continueInitApplication,
  updateApplication,
  processApplicationResponse,
  processAuthUserResponse,
  updateCompletedApplication,
  updateWithDiscount,
  getRecommendations,
  editCart,
  updateWithRecommendation
)
