import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PROGRESSIVE_STEP__FIELDS } from "containers/MultistepForm/constants/collections";
import {
  normalizeCardNumber,
  normalizeCVV,
  onlyNumbers,
} from "utils/normalize";
import { Field, reduxForm } from "redux-form";
import {
  isYearEqual4,
  maxLength15,
  minLength2,
  required,
  validateCreditCard,
  validateCVV,
} from "utils/validations";
import { TextField } from "components/Fields";
import { formValueSelector } from "redux-form";
const selector = formValueSelector("card_data_form");
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

import { CustomCheckbox } from "../../../../../components/Controls";
import {
  renderMonthField, renderYearSelectField,
} from '../../../Step2/input_helpers';
import {Stack} from '@mui/material';

class Form extends PureComponent {
  handleChange = () => {
    const { additionalData, onDataChange, valid } = this.props || {};
    onDataChange({
      data: additionalData,
      valid: valid,
    });
  };

  renderField = ({ input, meta }) => {
    const field = input.name;
    return (
      <TextField
        type={"text"}
        label={PROGRESSIVE_STEP__FIELDS[field].label}
        placeholder={PROGRESSIVE_STEP__FIELDS[field].placeholder}
        meta={meta}
        customChange={this.handleChange}
        {...input}
      />
    );
  };

  render() {
    return (
      <div>
        <div className="form-item-group">
          <div className="form-item">
            <Field
              name="firstName"
              validate={[required, minLength2, maxLength15]}
              component={this.renderField}
            />
          </div>
          <div className="form-item">
            <Field
              name="lastName"
              validate={[required, minLength2, maxLength15]}
              component={this.renderField}
            />
          </div>
          <div className="form-item">
            <Field
              name="creditCardNumber"
              normalize={normalizeCardNumber}
              validate={[required, validateCreditCard]}
              component={this.renderField}
            />
          </div>
          <div className="form-item">
            <div className="text-field-component flex flex--column">
              <label className="font-uppercased text-field-label">Exp. Date *</label>
              <Stack direction="row" sx={{gap: 2, '& > div': {width: '100%'}}}>
                <Field
                  name="expMonth"
                  validate={[required]}
                  component={renderMonthField}
                  normalize={onlyNumbers}
                />
                <Field
                  name="expYear"
                  validate={[required, isYearEqual4]}
                  component={renderYearSelectField}
                  normalize={onlyNumbers}
                />
              </Stack>
            </div>
          </div>
          <div className="form-item">
            <Field
              name="securityCode"
              normalize={normalizeCVV}
              validate={[required, validateCVV]}
              component={this.renderField}
            />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  onDataChange: PropTypes.func,
};

Form.defaultProps = {
  onDataChange: () => {},
};

Form = reduxForm({
  form: "card_data_form",
  initialValues: { isAddressTheSame: true },
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

Form = connect((state) => ({
  additionalData: selector(
    state,
    "firstName",
    "lastName",
    "creditCardNumber",
    "expMonth",
    "expYear",
    "securityCode",
    "isAddressTheSame"
  ),
  token: tokenSelector()(state),
}))(Form);

export default Form;
