import React, { Component } from "react";
import { CustomTooltip, LogoView } from "components/DataDisplay";
import { addThousandsSeparator } from "utils/helpers";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { prequalificationSelector } from "../../selectors";
import { providerFailed } from "../../actions";

import "./styles.scss";
import {
  AFF_LTO_TOOLTIP_TEXT,
  AFF_RISA_TOOLTIP_TEXT,
  FAST_ANSWER_TOOLTIP_TEXT,
} from "../../Step1/constants";

class OfferHeader extends Component {
  state = {
    selectedMultiOffer: {
      initial_payment: null,
    },
  };

  renderApprovedFor = () => {
    const { available, approvedAmount, specificProviderName } = this.props;

    return (
      <div>
        {specificProviderName === "snap"
          ? "Prequalified for:"
          : "Approved for:"}{" "}
        <span
          className={classNames("font-bold", {
            "font-red": !available,
          })}
        >
          ${addThousandsSeparator(approvedAmount)}
        </span>
      </div>
    );
  };

  renderDueToday = () => {
    const { dueToday } = this.props;

    if (dueToday === 0 || dueToday === "0") {
      return (
        <div className="due-today">
          <div className="fs-16 fs-m-12 text-right">Due Today:</div>
          <div className="text-right flex flex__align--center">
            <div className="fs-16 fs-m-12 expanded-block-component__note">
              No Money Down
            </div>
            <div className="font-bold fs-22 fs-m-20">(${dueToday})</div>
          </div>
        </div>
      );
    }
    else if (dueToday && dueToday.amount  && dueToday.info) {
      return (
        <div className="due-today">
          <div className="fs-16 fs-m-12 text-right">Due Today:</div>
          <div className="text-right flex flex--column">
            <div className="font-bold fs-22 fs-m-20">${dueToday.amount}</div>
            <div className="fs-16 fs-m-12 expanded-block-component__note">
              {dueToday.info}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="due-today">
          <div className="fs-16 fs-m-12 text-right">Due Today:</div>
          <div className="text-right due-width">
            <span className="font-bold fs-22 fs-m-20">${dueToday}</span>
          </div>
        </div>
      );
    }
  };

  renderPaymentInfoBlock = (vers) => {
    const {
      prequal,
      approvedAmount,
      paymentFrequency,
      typeOfPlan,
      paymentPeriod,
      showPlanTypeTooltip,
      specificProviderName
    } = this.props;

    return (
      <div
        className={classNames(
          `expanded-block-component__info* fs-16 fs-m-10 ${vers}`
        )}
      >
        <div className="order-info">
          {prequal && approvedAmount && (
            <div className="flex-col column-info info-block order-info-values">
              <div className="fs-12 info-block space-after">
                Approval Amount:
              </div>
              <div className="font-bold fs-14">
                {"$" + addThousandsSeparator(approvedAmount)}
              </div>
            </div>
          )}
          {!prequal && paymentFrequency && (
            <div className="flex-col column-info info-block order-info-values">
              <div className="fs-12 info-block space-after">
                Recurring Payment:
              </div>
              <div className="font-bold fs-14">
                {paymentFrequency}
              </div>
            </div>
          )}
          {paymentPeriod && (
            <div
              className={classNames(
                `flex-col column-info info-block order-info-values`
              )}
            >
              <div className="fs-12 info-block space-after">
                {prequal ? "Type of Plan:" : specificProviderName === "snap" ? "Months to Ownership" : "Payment Period:"}
              </div>
              <div className="font-bold fs-14">
                {prequal ? typeOfPlan : paymentPeriod}
                <span className="score-icon-additionals">
                  {showPlanTypeTooltip &&
                    prequal &&
                    (typeOfPlan === "Lease to Own" ||
                      typeOfPlan === "Retail Installment Plan") && (
                      <CustomTooltip
                        text={
                          typeOfPlan === "Lease to Own"
                            ? AFF_LTO_TOOLTIP_TEXT
                            : AFF_RISA_TOOLTIP_TEXT
                        }
                      />
                    )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { available, isExpanded, specificProviderName, approvedAmount } = this.props;
    return (
      <div>
        <div className="expanded-block-component__header">
          <div className="flex-row flex__justify--between flex__align--start">
            <div className="flex-col">
              <LogoView provider={specificProviderName} />
            </div>
            {!isExpanded && this.renderPaymentInfoBlock("desktop")}
            {available && this.renderDueToday()}
          </div>
          {!isExpanded && this.renderPaymentInfoBlock("mobile")}
          {!available && this.renderApprovedFor()}
        </div>
        {(specificProviderName === 'progressive' && isExpanded) && (
          <div className="flex flex--column">
            <p className="fs-16 progressive-additional-text">
              {`You have been pre-qualified to lease
              items up to ${"$" + addThousandsSeparator(approvedAmount)} in cash price with
              Progressive Leasing.`}
            </p>
            <p className="font-italic fs-14 progressive-additional-text">
              Approval conditioned on providing bank details for a qualified payment source. To
              complete your lease and receive your items, you must make an initial payment using a
              valid debit or credit card. The standard lease-to-own agreement offers 12 months to
              ownership and could cost more than double the cash price. Not all applicants are
              approved.
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  prequal: prequalificationSelector(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    providerFailed: (data) => dispatch(providerFailed(data)),
  };
};

OfferHeader = connect(mapStateToProps, mapDispatchToProps)(OfferHeader);

export default OfferHeader;
